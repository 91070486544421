/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
      allContentfulLink(sort: { fields: [createdAt], order: ASC }) {
        edges {
            node {
              title
              url
              createdAt
            }
        }
      }
    }
  `)

  return (
    <>
      <Header />
        <main>{children}</main>
       

        <Footer data={data}>
        To follow this course, you can download the source file, which will help you compare your progress. <a href="mailto:rob@jnprdsgn.com">Email us</a> to ask anything. © 2022

        </Footer>



        <footer
          style={{
            marginTop: `2rem`,
            padding: "24px",
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby </a>by Roberto Meireles JNPR DSGN
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
